import API from "@/api";

export default {
  getList: params => {
    return API.requestPost({
      data: params,
      url: "report/rechargeTrade",
      auth: true
    });
  }
};
