<template>
  <div class="main-wrap">
    <search-form @search="search"></search-form>
    <purchase-table :list="list" @sortChange="handleSort"></purchase-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :current-page="currentPage"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import purchaseTable from './components/purchase-table';
import searchForm from './components/search-form';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      searchData: {}
    };
  },
  components: {
    purchaseTable,
    searchForm
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = Object.assign(data, { pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>
