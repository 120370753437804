<template>
  <div class="account-table table-wrap">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
      :default-sort = "{prop: 'createdAt', order: 'descending'}"
      @sort-change="sortChange"
    >
      <el-table-column prop="tempId" label="ID" align="center"></el-table-column>
      <el-table-column prop="outTradeNo" label="流水号" align="center" min-width="120"></el-table-column>
      <el-table-column prop="chargeMerchant" label="充值商家" align="center"></el-table-column>
      <el-table-column prop="amount" label="充值金额(元)" align="center">
        <template slot-scope="scope">{{ scope.row.amount / 100 }}</template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="充值总金额(元)" sortable="custom" align="center" min-width="90">
        <template slot-scope="scope">{{ scope.row.totalAmount / 100 }}</template>
      </el-table-column>
      <el-table-column prop="createdAt" label="充值时间" min-width="135" sortable="custom" align="center"></el-table-column>
      <el-table-column label="支付方式" align="center">
        <template slot-scope="scope">{{ scope.row.payment | paymentText }}</template>
      </el-table-column>
      <el-table-column label="充值类型" align="center" v-if="pro">
        <template slot-scope="scope">{{ scope.row.type | paymentType }}</template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status | chargeStatusColor"
          >{{ scope.row.status | chargeStatus }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    paymentType: p => {
        switch (p) {
            case 1:
                return "余额";
            case 2:
                return "星币";
            default:
                return "未知";
        }
    },
    paymentText: p => {
        switch (p) {
            case 1:
                return "支付宝";
            case 2:
                return "微信";
            default:
                return "未知";
        }
    },
    chargeStatus: s => {
        switch (s) {
            case 0:
                return "待付款";
            case 1:
                return "充值成功";
            case -1:
                return "充值失败";
            default:
                return "未知";
        }
    },
    chargeStatusColor: s => {
        switch (s) {
            case 0:
                return "info";
            case 1:
                return "success";
            case -1:
                return "danger";
            default:
                return "danger";
        }
    }
  },
  methods:{
    sortChange(val){
      this.$emit('sortChange',val)
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
